import * as React from "react"
import Helmet from "../../components/helmet/helmet";
import NavBar from "../../components/navbar/navbar";
import "../index.scss"
import Footer from "../../components/footer/footer";
import Table from "../../components/table/table";
import PriceListCastorama from "./priceListCastorama.json";
import PriceListAtomia from "./priceListAtomia.json"
import MyPriceListAdditionalService from "./priceListAdditionalService.json";

import "./index.scss"

const PriceList = (props) => {
  const { location } = props;
  return (
    <div className="MainContainer Casto">
      <Helmet />
      <NavBar location={location} />
      <div id="BGImage"></div>
      <div className="Content priceList">
        <Table table={PriceListCastorama} />
        <Table table={PriceListAtomia} />
        <Table table={MyPriceListAdditionalService} />

        <div className="bottomList"><p>* Ceny usług są uzależnione od wielu czynników takich jak: rodzaj prac, ich wielkość oraz zróżnicowanie, warunki realizacji, zakres zadania, typ obiektu itp. W cenniku podano przybliżone ceny ważniejszych usług.</p>
          <p>* Inne prace nie ujete w cenniku wyceniane będą indywidualnie</p>  </div>

      </div>
      <Footer />
    </div>
  )
}

export default PriceList;