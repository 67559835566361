import * as React from "react"
import "./table.scss"

const Table = (props) => {

    const { table } = props;

    return (
        <div className="Table">
            <h4>{table.name}</h4>
            <table>
                <thead>
                    {table.header.map(el =>
                    (
                        <th>{el.name}</th>
                    ))}
                </thead>
                {table.body.map(el =>
                    <tbody>
                        {table.header.map(elm =>
                            <td>{typeof el[elm.key] == "number" ?el[elm.key].toFixed(2)+" PLN":el[elm.key]}</td>
                        )}
                    </tbody>
                )}
            </table>
        </div>
    )
}

export default Table;